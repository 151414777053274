import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Kontakt from "../views/Kontakt.vue";
import Leistungen from "../views/Leistungen.vue";
import Gallerie from "../views/Gallerie.vue";
import UberUns from "../views/Uber-uns.vue";
import AGB from "../views/AGB.vue";
import Impressum from "../views/Impressum.vue";
import Datenschutz from "../views/Datenschutz.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: Kontakt
  },
  {
    path: "/agb",
    name: "AGB",
    component: AGB
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz
  },
  {
    path: "/leistungen",
    name: "Leistungen",
    component: Leistungen
  },
  {
    path: "/Gallerie",
    name: "Gallerie",
    component: Gallerie
  },
  {
    path: "/uber-uns",
    name: "UberUns",
    component: UberUns
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
// eslint-disable-next-line
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});
export default router;
