<template>
  <div
    data-aos="fade-up"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-once="true"
    data-aos-anchor-placement="top-center"
  >
    <div class="container">
      <div class="kontakt-form">
        <div class="kontakt-daten">
          <div class="kontakt-daten-info">
            <h5>
              <span class="kontant-daten-icon"
                ><svg viewBox="0 0 24 24" width="1.2em" height="1.2em">
                  <path
                    fill="currentColor"
                    d="M5,3V21H11V17.5H13V21H19V3H5M7,5H9V7H7V5M11,5H13V7H11V5M15,5H17V7H15V5M7,9H9V11H7V9M11,9H13V11H11V9M15,9H17V11H15V9M7,13H9V15H7V13M11,13H13V15H11V13M15,13H17V15H15V13M7,17H9V19H7V17M15,17H17V19H15V17Z"
                  /></svg></span
              >IMMER GartenBau
            </h5>
            <h5>
              <span class="kontant-daten-icon"
                ><svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                  /></svg></span
              >Finanzstrasse 14, <br />
              46145 Oberhausen
            </h5>
            <h5>
              <span class="kontant-daten-icon"
                ><svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
                  /></svg></span
              >017636616027
            </h5>
            <h5>
              <span class="kontant-daten-icon"
                ><svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                  /></svg></span
              >immergartenbau@gmail.com
            </h5>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.862152908382!2d6.84369191587825!3d51.5157449179138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8eadd340bbbeb%3A0xa737cb87150698b2!2sFinanzstra%C3%9Fe%2014%2C%2046145%20Oberhausen!5e0!3m2!1sen!2sde!4v1613321566496!5m2!1sen!2sde"
            width="600"
            height="450"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>

        <h3 class="leistungen-title">KONTAKTFORMULAR</h3>
        <iframe width="80%" height="450" frameborder="0" allow="clipboard-write;camera;geolocation;fullscreen" src="https://buildbase.troni.app/embed/immergartenbau-contactform"></iframe>
       
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: "",
        subject: ""
      },
      errors: [],
      successfully: "",
      error550: ""
    };
  },
  watch: {
    form: {
      handler: function() {
        if (this.errors.length) this.errors = [];
      },
      deep: true
    }
  },
  methods: {
    onSubmit(evt) {
      this.checkForm(evt);
      if (!this.errors.length) {
        evt.preventDefault();
        axios
          .post("https://www.immergartenbau.com/api/mail", {
            name: this.form.name,
            email: this.form.email,
            subject: this.form.subject,
            text: this.form.message
          })
          .then(response => {
            if (response.data.message == "successfully") {
              this.successfully = "successfully";
              this.onReset();
            }
            if (response.data.message == "error") {
              this.error550 = "error";
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    success() {
      this.successfully = true;
    },
    onReset() {
      this.form.email = "";
      this.form.name = "";
      this.form.message = "";
      this.form.subject = "";
      this.errors = [];
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm: function(e) {
      this.errors = [];

      if (!this.form.name) {
        this.errors.push("Name erforderlich.");
      }
      if (!this.form.email) {
        this.errors.push("Email erforderlich.");
      } else if (!this.validEmail(this.form.email)) {
        this.errors.push("Gültig email erforderlich.");
      }
      if (!this.form.message) {
        this.errors.push("Nachricht erforderlich.");
      }
      if (!this.errors.length) {
        return true;
      }

      e.preventDefault();
    }
  }
};
</script>

<style></style>
