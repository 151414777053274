<template>
  <div>
    <div class="app">
      <!-- fillon menu -->
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <router-link to="/">
            <img alt="logo" src="./assets/logo3.png" />
          </router-link>
          <button
            class="navbar-toggler"
            style="color: white !important"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
              />
            </svg>
          </button>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="mr-auto navbar-nav"></ul>
            <ul class="navbar-nav">
              <li
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                :class="[
                  currentPage.includes('Home') ? 'nav-item active' : 'nav-item'
                ]"
              >
                <router-link class="nav-link" to="/">
                  <svg class="menu-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"
                    /></svg
                  >Home</router-link
                >
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                :class="[
                  currentPage.includes('Leistungen')
                    ? 'nav-item active'
                    : 'nav-item'
                ]"
              >
                <router-link class="nav-link" to="/leistungen">
                  <svg class="menu-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z"
                    /></svg
                  >Leinstungen</router-link
                >
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                :class="[
                  currentPage.includes('Gallerie')
                    ? 'nav-item active'
                    : 'nav-item'
                ]"
              >
                <router-link class="nav-link" to="/gallerie">
                  <svg class="menu-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z"
                    /></svg
                  >Gallerie
                </router-link>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                :class="[
                  currentPage.includes('UberUns')
                    ? 'nav-item active'
                    : 'nav-item'
                ]"
              >
                <router-link class="nav-link" to="/uber-uns">
                  <svg class="menu-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"
                    /></svg
                  >Uber uns</router-link
                >
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                :class="[
                  currentPage.includes('Kontakt')
                    ? 'nav-item active'
                    : 'nav-item'
                ]"
              >
                <router-link class="nav-link" to="/kontakt">
                  <svg class="menu-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                    /></svg
                  >Kontakt</router-link
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- mbaron menu -->

      <!-- fillon jumbotron -->
      <div class="container">
        <!-- mbaron jumbotron -->

        <router-view />
        <!-- fillon footer -->
        <div class="footer">
          <div class="footer-cards">
            <div class="footer-card">
              <div class="footer-card-title">Legal</div>
              <ul>
                <li>
                  <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z"
                    />
                  </svg>
                  <router-link to="/impressum"> Impressum </router-link>
                </li>
                <li>
                  <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z"
                    />
                  </svg>
                  <router-link to="/agb"> AGB </router-link>
                </li>
                <li>
                  <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z"
                    />
                  </svg>
                  <router-link to="/datenschutz">
                    Datenschutzerklärung
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="footer-card">
              <div class="footer-card-title">Impressum</div>
              <ul>
                <li>
                  <svg viewBox="0 0 24 24" style="width: 20px; height: 20px">
                    <path
                      fill="currentColor"
                      d="M5,3V21H11V17.5H13V21H19V3H5M7,5H9V7H7V5M11,5H13V7H11V5M15,5H17V7H15V5M7,9H9V11H7V9M11,9H13V11H11V9M15,9H17V11H15V9M7,13H9V15H7V13M11,13H13V15H11V13M15,13H17V15H15V13M7,17H9V19H7V17M15,17H17V19H15V17Z"
                    />
                  </svg>
                  IMMER GartenBau
                </li>
                <li>
                  <svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                    />
                  </svg>
                  Finanzstrasse 14, <br />
                  <span style="padding-left: 24px"> 46145 Oberhausen</span>
                </li>
              </ul>
            </div>
            <div class="footer-card">
              <div class="footer-card-title">Bürozeiten</div>
              <ul>
                <li>
                  <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 13.9L16.3 15.2L11 12.3V7H12.5V11.4L17 13.9Z"
                    />
                  </svg>
                  Mo - Sa: 7:00 - 17:00
                </li>
                <li>
                  <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 13.9L16.3 15.2L11 12.3V7H12.5V11.4L17 13.9Z"
                    />
                  </svg>
                  So: Geschlossen
                </li>
              </ul>
            </div>
            <!--  <div class="footer-card">
              <div class="footer-card-title">asd</div>
              <ul>
                <li>Mo - Sa: 7:00 - 17:00</li>
                <li>So: Geschlossen</li>
              </ul>
            </div> -->
          </div>
          <div class="copyright">
            ImmerGartenBau.Com
            <svg style="width: 18px; height: 18px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M10.08,10.86C10.13,10.53 10.24,10.24 10.38,10C10.5,9.74 10.72,9.53 10.97,9.37C11.21,9.22 11.5,9.15 11.88,9.14C12.11,9.15 12.32,9.19 12.5,9.27C12.71,9.36 12.89,9.5 13.03,9.63C13.17,9.78 13.28,9.96 13.37,10.16C13.46,10.36 13.5,10.58 13.5,10.8H15.3C15.28,10.33 15.19,9.9 15,9.5C14.85,9.12 14.62,8.78 14.32,8.5C14,8.22 13.66,8 13.24,7.84C12.82,7.68 12.36,7.61 11.85,7.61C11.2,7.61 10.63,7.72 10.15,7.95C9.67,8.18 9.27,8.5 8.95,8.87C8.63,9.26 8.39,9.71 8.24,10.23C8.09,10.75 8,11.29 8,11.87V12.14C8,12.72 8.08,13.26 8.23,13.78C8.38,14.3 8.62,14.75 8.94,15.13C9.26,15.5 9.66,15.82 10.14,16.04C10.62,16.26 11.19,16.38 11.84,16.38C12.31,16.38 12.75,16.3 13.16,16.15C13.57,16 13.93,15.79 14.24,15.5C14.55,15.25 14.8,14.94 15,14.58C15.16,14.22 15.27,13.84 15.28,13.43H13.5C13.5,13.64 13.43,13.83 13.34,14C13.25,14.19 13.13,14.34 13,14.47C12.83,14.6 12.66,14.7 12.46,14.77C12.27,14.84 12.07,14.86 11.86,14.87C11.5,14.86 11.2,14.79 10.97,14.64C10.72,14.5 10.5,14.27 10.38,14C10.24,13.77 10.13,13.47 10.08,13.14C10.03,12.81 10,12.47 10,12.14V11.87C10,11.5 10.03,11.19 10.08,10.86M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20Z"
              />
            </svg>
            2021
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data: () => ({}),
  mounted() {
    this.$store.dispatch("getimages");
    /*  this.$notify({
      group: "foo",
      title: "Cookie",
      text: "Diese Website verwendet keine Cookies!",
      duration: 5000
    }); */
  },
  computed: {
    currentPage() {
      return this.$route.name;
    }
  }
};
</script>
<style></style>
