<template>
  <div>
    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <div class="mt-2">
        <div class="container">
          <h1 class="leistungen-title">Leistungen</h1>
          <div class="mt-5">
            <div class="text-center">
              <h2>Unser Leistungs­spektrum</h2>
              <p class="mt-3">
                Bei IMMER Gartenbau erhalten Sie professionelle Unterstützung
                für alle Phasen Ihres Projektes. <br />
                Von der Beratung bis zur Gartenpflege – wir kümmern uns gerne um
                Ihr Anliegen.
              </p>
            </div>

            <div
              class="mt-5 mb-5"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-easing="ease-in-out"
              data-aos-once="true"
            >
              <div class="highlight-items">
                <div class="highlight-image">
                  <img alt="logo" src="../assets/images/leistungen-img.jpg" />
                </div>
                <div class="highlight-items-text">
                  <h5 class="highlight-text-body">
                    <h1 class="highlight-text-title">
                      Wir nehmen uns Ihrem Gartenparadies an.
                    </h1>
                    <hr />

                    Mit kreativen Ideen wollen wir Ihre Vorstellungen und
                    Wünsche von Ihrem Garten verwirklichen. Ob Gestaltung oder
                    Pflege Ihrer grünen Oase, wir führen unsere Arbeiten
                    termin-, fachgerecht und präzise aus. Wir hauchen Ihrem
                    Garten Leben ein. Lehnen Sie sich entspannt zurück und
                    freuen Sie sich auf Ihr grünes Wohnzimmer.
                  </h5>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up-right"
              data-aos-duration="800"
              data-aos-easing="ease-in-out"
              data-aos-once="true"
            >
              <h1 class="leistungen-title">Leistungs­spektrum im Gartenbau</h1>
              <div class="leistungen">
                <div class="lestungen-list">
                  <ul>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Gartengestaltung
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Natursteinmauern
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Platten- und Pflasterarbeiten
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Terrassen, Wege und Plätze
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Hofeinfahrten und Hauseingänge
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Stufen
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Hangbefestigungen
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Neuanlage Rasenfläche
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Dachbegrünungen
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Bodenregulierung
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Zaunanlagen
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Sichtschutz
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Holzterrassen
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Neuanlage von Gärten
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Gartenpflege
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Teichanlagen
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Instandsetzungen
                    </li>
                    <li>
                      <span
                        ><svg
                          style="width: 24px; height: 24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                          /></svg></span
                      >Winterdienst
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
