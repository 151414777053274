<template>
  <div>
    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <div class="mt-2">
        <div class="container">
          <h1 class="leistungen-title">Realisierte Projekte</h1>
          <div class="justify-content-center">
            <div class="row justify-content-center">
              <div
                v-for="(image, i) in $store.state.images"
                :key="i"
                class="imagee col-md-2 col-4"
              >
                <img class="img" :src="image" @click="index = i" />
              </div>
            </div>
          </div>
          <vue-gallery-slideshow
            :images="$store.state.images"
            :index="index"
            @close="index = null"
          ></vue-gallery-slideshow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  data() {
    return {
      index: null
    };
  },
  components: {
    VueGallerySlideshow
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style></style>
