<template>
  <div class="home">
    <div data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
      <div class="jumbotron">
        <h1 class="display-4">Wir machen Ihren Garten zum Highlight!</h1>
        <p class="lead">
          Unser Team bringt Ihr privates und öffentliches Grün in Topform. <br> Mit
          außergewöhnlichen Gestaltungsideen, professioneller Planung und
          liebevoller Ausführung bekommen Sie bei uns alles rund um Natur und
          Garten.
        </p>
        <hr class="my-4" />
        <p>Jetzt kostenfreien Beratungstermin vereinbaren.</p>
        <p class="lead">
          <a
            class="btn btn-success btn-lg"
            href="tel:017636616027"
            role="button"
          >
            <span style="font-size: 20px">017636616027</span></a
          >
        </p>
      </div>
    </div>
    <div data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
      <div class="highlight-text">
        <h1 class="highlight-text-title">
          Ihr Garten und Landschaftsbau in ganz NRW
        </h1>

        <h5 class="highlight-text-body">
          <p>
            Bei uns liegt Ihr Gartenbauprojekt immer in den richtigen Händen.
            Unser Service umfasst sämtliche Projektphasen von der Planung bis
            zur Pflege. Ihre Wünsche verlieren wir dabei nie aus dem Blick.
            Lassen Sie sich von unserer professionellen Arbeitsweise überzeugen! <br>
            Bei dem Thema Gartenbau stehen wir Ihnen mit Rat und Tat zur Seite.
          </p>
        </h5>
      </div>
    </div>
    <div
      data-aos="flip-up"
      data-aos-duration="800"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <div class="services">
        <div class="services-card">
          <div class="services-card-header">Beratung</div>
          <div class="services-card-image">
            <svg
              viewBox="-8 0 464 464.00002"
              width="80%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style="fill: #4b7f52"
                d="m224 121.574219 17.054688-25.574219h-34.109376zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m0 272c0 22.089844 17.910156 40 40 40h48c13.253906 0 24 10.746094 24 24v112h32v-144c0-22.089844-17.910156-40-40-40h-32c-13.253906 0-24-10.746094-24-24v-88c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v88c0 4.417969 3.582031 8 8 8h96v-32h-72v-80c0-13.253906-10.746094-24-24-24h-32c-22.089844 0-40 17.910156-40 40zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m448 272v-120c0-22.089844-17.910156-40-40-40h-32c-13.253906 0-24 10.746094-24 24v80h-72v32h96c4.417969 0 8-3.582031 8-8v-88c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v88c0 13.253906-10.746094 24-24 24h-32c-22.089844 0-40 17.910156-40 40v144h32v-112c0-13.253906 10.746094-24 24-24h48c22.089844 0 40-17.910156 40-40zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m152 200h32v48h80v-48h32v-64c-.066406-20.480469-15.621094-37.585938-36-39.59375l-36 53.992188-36-54.015626c-20.390625 2.007813-35.945312 19.128907-36 39.617188zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m16 325.199219h16v138.800781h-16zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m416 325.199219h16v138.800781h-16zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m232 312h-16v136h-32v16h80v-16h-32zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m416 56c0 22.089844-17.910156 40-40 40s-40-17.910156-40-40 17.910156-40 40-40 40 17.910156 40 40zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m288.640625 296c1.75-12.164062 7.460937-23.410156 16.246094-32h-161.773438c8.785157 8.589844 14.496094 19.835938 16.246094 32zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m264 40c0 22.089844-17.910156 40-40 40s-40-17.910156-40-40 17.910156-40 40-40 40 17.910156 40 40zm0 0"
              />
              <path
                style="fill: #4b7f52"
                d="m112 56c0 22.089844-17.910156 40-40 40s-40-17.910156-40-40 17.910156-40 40-40 40 17.910156 40 40zm0 0"
              />
            </svg>
          </div>
          <div class="services-card-body">
            Wir beraten Sie persönlich vor Ort
          </div>
        </div>

        <div class="services-card">
          <div class="services-card-header">Planung</div>
          <div class="services-card-image">
            <svg
              id="Layer_5"
              enable-background="new 0 0 64 64"
              viewBox="0 0 64 64"
              width="80%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  style="fill: #4b7f52"
                  d="m52 33.256v-2.563l.862-.323c4.27-1.601 7.138-5.74 7.138-10.3v-5.07c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2h-.059c-.5-4.493-4.317-8-8.941-8h-8c-4.624 0-8.442 3.507-8.941 8h-.059c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h2v1.697l-1.487 2.229c-.336.505-.513 1.092-.513 1.697 0 1.23.684 2.336 1.785 2.888l.215.107v2.382c0 2.757 2.243 5 5 5h3v2.124l-1.178.157c-5.679.758-10.447 4.459-12.613 9.719h-5.209v-13h6v-8h-14v3h-2v-3h-6v-6h6v-10h-14v10h6v6h-6v8h14v-3h2v3h6v13h-4v-8h-5c-2.757 0-5 2.243-5 5v18c0 2.757 2.243 5 5 5h29v-4h9.402c5.844 0 10.598-4.755 10.598-10.599 0-6.905-4.486-13.025-11-15.145zm-49-25.256h10v6h-10zm10 20h-10v-4h10zm6-4h10v4h-10zm39-3.93c0 3.73-2.347 7.117-5.84 8.427l-.16.06v-2.057l2.267-1.7c1.085-.815 1.733-2.111 1.733-3.467 0-2.277-1.766-4.149-4-4.32v-2.013h6zm-15-17.07h8c3.519 0 6.432 2.614 6.92 6h-21.84c.488-3.386 3.401-6 6.92-6zm-9 8h26v2h-26zm7 18c-1.654 0-3-1.346-3-3v-3.618l-1.32-.66c-.42-.21-.68-.631-.68-1.099 0-.21.062-.413.177-.587l1.823-2.733v-2.303h12v4h1.667c1.286 0 2.333 1.047 2.333 2.333 0 .73-.349 1.429-.934 1.867l-3.066 2.3v8.5c0 1.103-.897 2-2 2s-2-.897-2-2v-5zm-27 8h3v6h-3c-1.654 0-3-1.346-3-3s1.346-3 3-3zm27 24h-27c-1.654 0-3-1.346-3-3v-14.026c.838.635 1.87 1.026 3 1.026h27v4c-2.206 0-4 1.794-4 4v2c0 2.206 1.794 4 4 4zm4-4h-4c-1.103 0-2-.897-2-2v-2c0-1.103.897-2 2-2h1.757c.534 0 1.036.208 1.415.586l.828.828zm7.402 0h-5.402v-4h3.879c1.634 0 3.171-.637 4.328-1.793 1.156-1.155 1.793-2.692 1.793-4.328v-2.879h-2v2.879c0 1.102-.429 2.136-1.207 2.914-.768.767-1.83 1.207-2.914 1.207h-4.465l-.828-.828c-.698-.698-1.61-1.089-2.586-1.148v-6.024h-10.602c2.026-4.193 6.001-7.111 10.689-7.736l1.092-.145c.487 1.659 2.006 2.881 3.821 2.881 1.751 0 3.226-1.138 3.767-2.707 5.486 1.966 9.233 7.209 9.233 13.108 0 4.742-3.857 8.599-8.598 8.599z"
                />
                <path style="fill: #4b7f52" d="m5 10h6v2h-6z" />
                <path style="fill: #4b7f52" d="m49 42h4v2h-4z" />
              </g>
            </svg>
          </div>
          <!--   <div class="services-card-image">asd</div> -->
          <div class="services-card-body">
            Wir planen nach Ihren Vorstellungen
          </div>
        </div>

        <div class="services-card">
          <div class="services-card-header">Realisierung</div>
          <div class="services-card-image">
            <svg
              version="1.1"
              width="80%"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 493.172 493.172"
              style="enable-background: new 0 0 493.172 493.172"
              xml:space="preserve"
            >
              <g>
                <path
                  style="fill: #4b7f52"
                  d="M192.425,108.817c30.049,0,54.414-24.355,54.414-54.405S222.474,0,192.425,0c-30.05,0-54.413,24.363-54.413,54.413
		S162.375,108.817,192.425,108.817z"
                />
                <path
                  style="fill: #4b7f52"
                  d="M407.704,77.668V51.67c0-4.553-3.684-8.236-8.236-8.236c-4.553,0-8.236,3.684-8.236,8.236v25.996h-20.124V51.67
		c0-4.553-3.684-8.236-8.236-8.236c-4.553,0-8.236,3.684-8.236,8.236v25.996H334.51V51.67c0-4.553-3.684-8.236-8.236-8.236
		c-4.552,0-8.236,3.684-8.236,8.236v25.996h-20.124V51.67c0-4.553-3.684-8.236-8.236-8.236c-4.553,0-8.236,3.684-8.236,8.236v25.996
		h-0.001c-4.56,0-8.251,3.692-8.251,8.252v10.939c0,4.561,3.691,8.252,8.251,8.252h50.76l-0.135,67.852
		c-0.173,0.085-0.358,0.121-0.531,0.21l-36.203,18.749l-32.768-47.777c-1.764-2.566-3.994-4.639-6.248-6.666
		c-0.096-7.186-4.097-14.026-10.982-17.335l-10.848-5.228c-3.194-1.536-6.758-2.349-10.304-2.349c-3.58,0-7.176,0.82-10.393,2.389
		c-4.926,2.415-8.75,6.332-11.028,11.116h-20.67c-2.276-4.793-6.114-8.718-11.035-11.108c-3.187-1.561-6.798-2.397-10.401-2.397
		c-3.547,0-7.102,0.813-10.296,2.349l-10.865,5.236c-7.756,3.731-11.644,11.957-10.554,20.133
		c-3.715,4.157-6.721,9.005-8.534,14.573L78.452,283.815c-4.223,12.974,2.872,26.913,15.853,31.136
		c2.534,0.828,5.115,1.222,7.649,1.222c5.252,0,10.161-1.861,14.268-4.921c0.575,10.229,7.16,18.816,16.33,22.286v134.637
		c0,13.803,11.196,24.997,24.997,24.997c13.811,0,24.999-11.188,24.999-24.997V335.243h19.753v132.932
		c0,13.81,11.189,24.997,25,24.997c13.81,0,24.998-11.188,24.998-24.997V333.537c9.598-3.633,16.473-12.846,16.473-23.702v-74.063
		c12.249,8.85,28.703,10.683,42.798,3.394l20.37-10.55l-0.567,252.114c-0.016,6.82,5.501,12.362,12.331,12.378
		c0.008,0,0.016,0,0.024,0c6.813,0,12.338-5.51,12.354-12.33l0.597-265.3c10.237-6.922,13.983-20.483,8.162-31.73
		c-1.95-3.768-4.794-6.723-8.072-8.955l0.139-69.684h50.779c4.561,0,8.252-3.692,8.252-8.252V85.918
		C415.94,81.364,412.257,77.679,407.704,77.668z M252.299,309.835c0,4.939-3.997,8.935-8.936,8.935H141.486
		c-4.939,0-8.934-3.997-8.934-8.935V206.672c0-0.917,0.41-1.786,1.116-2.357c9.676-7.915,16.289-23.132,16.289-40.788
		c0-9.065-1.778-17.469-4.786-24.62c-0.628-1.496,0.023-3.217,1.48-3.917l10.858-5.236c0.991-0.482,2.067-0.716,3.145-0.716
		c1.093,0,2.187,0.249,3.202,0.74c2.001,0.974,3.45,2.815,3.915,4.995c4.996,22.963,14.157,38.366,24.653,38.366
		c10.487,0,19.65-15.402,24.645-38.373c0.466-2.172,1.913-4.014,3.917-4.995c1.007-0.491,2.101-0.732,3.194-0.732
		c1.077,0,2.155,0.233,3.152,0.716l10.865,5.236c1.457,0.7,2.109,2.429,1.481,3.917c-3.007,7.15-4.785,15.555-4.785,24.62
		c0,17.656,6.61,32.873,16.287,40.788c0.708,0.58,1.118,1.44,1.118,2.357V309.835z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
          <div class="services-card-body">
            <p>
              Wir realisieren Ihr Projekt fachgerecht und schnell
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      data-aos="fade-left"
      data-aos-duration="800"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <div class="highlight-items">
        <div class="highlight-image">
          <img alt="logo" src="../assets/images/webpc-passthru.webp" />
        </div>
        <div class="highlight-items-text">
          <h5 class="highlight-text-body">
            <h1 class="highlight-text-title">
              Wir nehmen uns Ihrem Gartenparadies an.
            </h1>
            <hr />
            <p>
              Wir nehmen uns Ihrem Gartenparadies an. Die Natur ist ein
              einzigartiges Schmuckstück, das können Sie schon in Ihrem Garten
              beobachten:  <br>Hier entfaltet sich Ihr Gartenparadies im Frühling in ihrer ganzen
              Pracht. Damit das auch so bleibt, haben wir - Ihr Meisterbetrieb
              für Garten- & Landschaftsbau - es uns zur Aufgabe gemacht, Ihren
              Garten zu gestalten und nach Ihren Vorstellungen zu formen. <br> Unser
              umfangreiches Angebot umfasst die Fällung und Pflege von Bäumen,
              Dachbegrünung, Pflanzungen, Heckenschnitt, Beseitigung von
              Gartenabfall, Landschaftsbau, Pflasterbau, Pflasterarbeiten,
              Zaunanlagen, Pflanzenpflege, Grünanlagenpflege, Schwimmteiche,
              Obstbaumschnitt uvm.  <br> Ebenfalls übernehmen wir im Bezug auf (Whirl-)Poolbau den gesamten Prozess - von der Beckenwahl bis zur Fertigstellung inkl. Technik und der Ersteinrichtrung.<br>Mit IMMER Gartenbau haben Sie die
              perfekte Wahl getroffen, wenn es darum geht Ihren Garten neu zu
              planen, zu verschönern oder diesen zu pflegen und gesund zu
              erhalten. Gerne helfen unsere Landschaftsgärtner mit wunderschönen
              und gesunden Gärten auszustatten.
            </p>
          </h5>
        </div>
      </div>
    </div>
    <div
      data-aos="fade-up-right"
      data-aos-duration="800"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <h1 class="leistungen-title">Unsere Leistungen</h1>
      <div class="leistungen">
        <div class="lestungen-list">
          <ul>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Gartengestaltung
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Natursteinmauern
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Platten- und Pflasterarbeiten
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Terrassen, Wege und Plätze
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >(Whirl-)Poolbau inkl. Technik
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Hofeinfahrten und Hauseingänge
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Stufen
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Hangbefestigungen
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Neuanlage Rasenfläche
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Dachbegrünungen
            </li>
          </ul>
          <ul>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Bodenregulierung
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Zaunanlagen
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Sichtschutz
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Holzterrassen
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Neuanlage von Gärten
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Gartenpflege
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Teichanlagen
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Instandsetzungen
            </li>
            <li>
              <span
                ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                  /></svg></span
              >Winterdienst
            </li>
          </ul>
        </div>
        <div class="leistungen-image">
          <img
            width="350px"
            alt="logo"
            src="../assets/images/gardener-leistungen.png"
          />
        </div>
      </div>
    </div>
    <!--   <div class="alert alert-success text-center mt-2 mb-2">
      <h3>
        Jetzt kostenfreien Beratungstermin vereinbaren.
        <a class="btn btn-success btn-lg" href="tel:017636616027" role="button">
          <span style="font-size: 20px"
            ><svg width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg>
            017636616027</span
          ></a
        >
      </h3>
    </div> -->
    <div
      data-aos="fade-up-left"
      data-aos-duration="800"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <h1 class="leistungen-title">Realisierte Projekte</h1>

      <div class="image-gallery">
        <div
          v-for="(image, i) in lastTenImg"
          :key="i"
          class="imagee col-md-2 col-4"
        >
          <img class="img" :src="image" @click="index = i" />
        </div>
        <vue-gallery-slideshow
          :images="lastTenImg"
          :index="index"
          @close="index = null"
        ></vue-gallery-slideshow>
      </div>
      <div class="projekt-button">
        <router-link to="/gallerie">
          <button class="btn btn-lg btn-success">Alle Projekten</button>
        </router-link>
      </div>
    </div>
    <hr />
    <div data-aos="fade-up" data-aos-duration="1800" data-aos-once="true">
      <div class="highlight-items">
        <div class="highlight-items-text">
          <h5 class="highlight-text-body">
            <h1 class="highlight-text-title">
              Ihre Gartengestaltung ist unsere Leidenschaft.
            </h1>
            <hr />
            <p>
              Mehr zum Leistungsspektrum finden Sie unter der Rubrik
              „Leistungen“. <br>Mit IMMER Gartenbau sind Sie auf der sicheren Seite,
              wenn es um die Gestaltung Ihres Gartens geht. Unsere Kunden
              profitieren von unserer langjährigen Erfahrung als
              Landschaftsgärtner sowie von unserer Kreativität und dem
              Ideenreichtum unseres Teams von IMMER Gartenbau. Egal ob Gärten
              von Privatpersonen oder öffentliche Gärten an Schulen oder Parks –
              wir verschönern mit unserem Gartenbau. Der eigene Garten ist ein Ort der Entspannung und
              des Wohlbefindens. Wenn die Vögel anfangen zu zwitschern und die
              dichte Schneedecke im Frühling langsam verschwindet, beginnt auch
              wieder die Gartensaison. <br>Jetzt können Sie Ihrer Kreativität bei
              der Planung Ihres Gartens freien Lauf lassen und den Garten neu
              dekorieren oder Sie entspannen sich auf Ihrer Holzterrasse und
              beobachten das bunte Treiben. Ist man einmal in den Genuss eines
              eigenen Gartens gekommen, möchte man sich diesen nicht mehr
              wegdenken.
            </p>
          </h5>
        </div>
        <div class="highlight-image">
          <img alt="logo" src="../assets/images/leidenschaft.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  name: "Home",
  components: {
    VueGallerySlideshow
  },
  data: function() {
    return {
      index: null
    };
  },
  computed: {
    lastTenImg() {
      return this.$store.getters.lastTenImg;
    }
  }
};
</script>
